import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { StateTypes } from "../../model/data";
import { isEmpty } from "lodash";

const la = JSON.parse(window.sessionStorage.getItem("local_authority") ?? "{}");

export default function AdminRoute({
  path,
  component: Component,
  ...rest
}: any) {
  const loggedIn = useMemo(() => {
    const userToken = window.sessionStorage.getItem("currentUser");
    return userToken;
    //eslint-disable-next-line
  }, [window.sessionStorage.getItem("currentUser")]);
  const { isAdmin } = useSelector((state: StateTypes) => state.user);
  const isDnoUser = useSelector((s: StateTypes) => s.user.isDsoUser);

  const isLaSelected = useMemo(() => {
    const la = window.sessionStorage.getItem("local_authority");
    return !isEmpty(la);
    //eslint-disable-next-line
  }, [window.sessionStorage.getItem("local_authority")]);

  return (
    <Route
      {...rest}
      render={(props: any) => {
        //
        //------Checking for feature access brfore navigating
        //
        if (!rest.feature) return <Redirect to="no-page" />;
        else
          return loggedIn && isDnoUser && !isLaSelected ? (
            <Redirect to={"/auth/select-la"} />
          ) : loggedIn && isAdmin && isLaSelected ? (
            <Component {...props} />
          ) : (
            <Redirect to={`/la/${la.name}/home`} />
          );
      }}
    />
  );
}
