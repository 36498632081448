import { notification } from "antd";

export const successPrompt = (data: {
  message: string;
  description?: string;
}) => {
  const { message, description } = data;
  return notification.success({
    placement: "bottomRight",
    message,
    description,
  });
};

export const errorPrompt = (data: {
  message: string;
  description?: string;
}) => {
  const { message, description } = data;
  return notification.error({
    placement: "bottomRight",
    message,
    description,
  });
};

export const warningPrompt = (data: {
  message: string;
  description?: string;
}) => {
  const { message, description } = data;
  return notification.warn({
    placement: "bottomRight",
    message,
    description,
  });
};
