import {
  Alert,
  Card,
  Input,
  Modal,
  Spin,
  Tabs,
  Typography,
  notification,
} from "antd";
import { useQuery } from "react-query";
import useAxios from "../../hooks/useAxios";
import { errorPrompt } from "./Prompt";
import { useDispatch, useSelector, batch } from "react-redux";
import { StateTypes } from "../../model/data";
import { setContinueWorking, setLaModalVisible } from "../../model";
import { useMemo, useState } from "react";
import { hotjar } from "react-hotjar";
import { isEmpty } from "lodash";
import { updateMasterPlanRole } from "../../model/user";
import { useLocation } from "react-router-dom";

const SelectLa = () => {
  const visible = useSelector((s: StateTypes) => s.global.laModalVisible);
  const [la, setLa] = useState<any>(undefined);
  const [laLoading, setLaLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<any>("");
  const [cty_search, setCtySearch] = useState<any>("");
  const [activeTab, setActiveTab] = useState<string>("la");
  const axios = useAxios();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state: StateTypes) => state.user.currentUser);

  const getLa = async () => {
    return await axios.get(`${process.env.REACT_APP_GET_USER_LA}`);
  };
  const { data, isLoading, isError } = useQuery({
    queryKey: "la",
    queryFn: getLa,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: 2,
  });
  if (isError) {
    errorPrompt({
      message: "Network Error!",
      description: "Could not get user la's",
    });
  }

  const LaList = useMemo(() => {
    return isEmpty(data)
      ? []
      : data?.data?.data?.active_la_list
          .filter((item: any) => item["area_type"] === "LA")
          .sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
  }, [data]);

  const countyList = useMemo(() => {
    return isEmpty(data)
      ? []
      : data?.data?.data?.active_la_list
          .filter((item: any) => item["area_type"] === "CTY")
          .sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
  }, [data]);

  const setLocalAuthority = async () => {
    if (!isEmpty(la)) {
      try {
        setLaLoading(true);
        const {
          data: { data },
        } = await axios.get(`/ls/${la.dno}/la/${la.name}/boundarydata/`);

        const {
          data: { data: res },
        } = await axios.get(`${process.env.REACT_APP_LA_USER_DETAILS}`);

        window.Intercom("update", {
          local_authority: la.description,
          la_code: la.name,
        });

        const cntWok = {
          consulation_exists: data.consulation_exists,
          project_exists: data.project_exists,
          scenario_exists: data.scenario_exists,
        };

        batch(() => {
          dispatch(updateMasterPlanRole(res.master_plan_role));
          dispatch(setContinueWorking(cntWok));
        });

        window.sessionStorage.setItem(
          "local_authority",
          JSON.stringify({ ...la, ...data })
        );
        // update la info in hotjar for ukpn prod
        const dnoName: string = process.env.REACT_APP_DNO_NAME!;
        const appStage: string = process.env.REACT_APP_STAGE!;

        if (dnoName === "ukpn" && appStage === "prod") {
          if (hotjar.initialized()) {
            if (data.id && data.name && la?.description) {
              hotjar.identify(`${user.id}-${la.name}`, {
                name: user.name,
                email: user.email,
              });
            }
          } else {
            console.log("Hotjar is not initialized");
          }
        }

        setLaLoading(false);
        setTimeout(() => {
          window.location.replace(`/la/${la.name}/home`);
        }, 500);
      } catch (err) {
        errorPrompt({
          message: "Could not get LA boundary",
        });
        setLaLoading(false);
      }
    } else
      notification.warning({
        message: "Please select a local authority",
        description:
          "if you wish to continue, Please select a different local authority",
        placement: "bottomRight",
      });
  };

  const selected_la = useMemo(() => {
    if (isEmpty(la))
      return JSON.parse(
        window.sessionStorage.getItem("local_authority") ?? "{}"
      );
    else return la;
  }, [la]);

  return (
    <Modal
      title={
        <Typography.Title level={4} style={{ margin: 0 }}>
          {`Select Your ${
            activeTab === "la"
              ? "Districts & Unitary Authorities"
              : "Counties & Combined"
          }`}
        </Typography.Title>
      }
      centered
      open={visible}
      closable={false}
      onCancel={() => {
        setLa(undefined);
        dispatch(setLaModalVisible(false));
      }}
      onOk={setLocalAuthority}
      okButtonProps={{
        type: "primary",
        style: {
          backgroundColor: isEmpty(selected_la) || location.pathname.includes(selected_la?.name)
            ? "#E0E0E0"
            : "var(--default-cta)",
          width: "49.2%",
          border: "none",
        },
        disabled: isEmpty(selected_la) || location.pathname.includes(selected_la?.name),
        loading: laLoading,
      }}
      cancelButtonProps={{
        type: "primary",
        style: {
          backgroundColor: "var(--default-grey)",
          width: "49.2%",
          border: "none",
        },
      }}
      okText="Continue"
      width={600}
      bodyStyle={{
        maxHeight: 250,
        overflowY: "scroll",
        padding: "8px 24px",
      }}
      maskClosable={false}
    >
      <Alert
        showIcon
        type="info"
        description="Users with access to multiple regions can switch between local authority dashboards by choosing from the list below."
        style={{ padding: 8 }}
      />
      {isLoading ? (
        <Spin style={{ width: "100%" }} />
      ) : (
        <Tabs
          type="card"
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
          style={{ margin: "12px 0px" }}
          className="la-selection"
        >
          <Tabs.TabPane tab="Districts & Unitary Authorities" key="la">
            <Input.Search
              placeholder="Search local authority..."
              allowClear
              onChange={({ target: { value } }) => setSearch(value)}
              style={{ marginBottom: 16 }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                flexWrap: "wrap",
                gap: 12,
              }}
            >
              {LaList?.filter(
                (item: any) =>
                  item.description
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  item.name.toLowerCase().includes(search.toLowerCase())
              ).map((item: any) => (
                <Card
                  hoverable
                  key={item.la_id}
                  style={{
                    backgroundColor:
                      item.la_id === selected_la?.la_id
                        ? "var(--light-blue)"
                        : "var(--default-white)",
                    borderRadius: 4,
                  }}
                  onClick={() => setLa(item)}
                  bodyStyle={{ padding: 8 }}
                >
                  <Typography.Text>{item.description}</Typography.Text>
                </Card>
              ))}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Counties & Combined" key="county">
            <Input.Search
              placeholder="Search counties..."
              allowClear
              onChange={({ target: { value } }) => setCtySearch(value)}
              style={{ marginBottom: 16 }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                flexWrap: "wrap",
                gap: 12,
              }}
            >
              {countyList
                ?.filter(
                  (item: any) =>
                    item.description
                      .toLowerCase()
                      .includes(cty_search.toLowerCase()) ||
                    item.name.toLowerCase().includes(cty_search.toLowerCase())
                )
                .map((item: any) => (
                  <Card
                    hoverable
                    key={item.la_id}
                    style={{
                      backgroundColor:
                        item.la_id === selected_la?.la_id
                          ? "var(--light-orange)"
                          : "var(--default-white)",
                      borderRadius: 4,
                    }}
                    onClick={() => setLa(item)}
                    bodyStyle={{ padding: 8 }}
                  >
                    <Typography.Text>{item.description}</Typography.Text>
                  </Card>
                ))}
            </div>
          </Tabs.TabPane>
        </Tabs>
      )}
    </Modal>
  );
};

export default SelectLa;
