import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { StateTypes } from "../model/data";
import { isExpired } from "react-jwt";
import { updateToken } from "../model";
import { isString } from "lodash";
import { updateAccessToken } from "../model/user";

const la = JSON.parse(window.sessionStorage.getItem("local_authority") ?? "{}");

export function processUrl(config: any, laval?: any) {
  const options: Record<string, string> = {
    "{dno}": `${process.env.REACT_APP_DNO_NAME}`,
    "{la}": laval? laval : la.name,
  };
  let url = config.url ?? config;
  Object.keys(options).forEach((item) => {
    url = url?.replaceAll(item, options[item]);
  });
  if (isString(config)) return url;
  else return Promise.resolve(url);
}

export function axiosUnAuth(la?: any) {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
  });
  instance.interceptors.request.use(
    async (config) => {
      config.url = await processUrl(config, la);
      return config;
    },
    (error) => Promise.reject(error)
  );
  return instance;
}

function useAxios() {
  const { uid, token, refreshToken } = useSelector(
    (state: StateTypes) => state.user.currentUser
  );
  const isTokenExpired = isExpired(token);

  const dispatch = useDispatch();

  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      if (isTokenExpired) {
        if (refreshToken) {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BASE_URL}${processUrl(
              process.env.REACT_APP_REFRESH_TOKEN?.replace(
                "{dno}",
                `${process.env.REACT_APP_DNO_NAME}`
              )
            )}`,
            {
              params: {
                username: uid,
                refresh_token: refreshToken,
              },
            }
          );
          dispatch(updateToken(data.data.idToken));
          dispatch(updateAccessToken(data.data.accessToken));
          config.headers["Authorization"] = `Bearer ${data.data.idToken}`;
        }
      }
      config.url = await processUrl(config);
      return config;
    },
    (error) => Promise.reject(error)
  );

  return axiosInstance;
}

export default useAxios;
