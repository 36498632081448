import { Button, Result, Space } from "antd";
import { Component } from "react";

const la = JSON.parse(window.sessionStorage.getItem("local_authority") ?? "{}");

class ErrorBoundary extends Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    //@ts-ignore
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Result
            title="Sorry, Something went wrong"
            subTitle="Seems like we ran into an issue. Please reload or navigate to home page"
            status="warning"
            extra={
              <Space>
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "var(--default-cta)",
                    border: "none",
                  }}
                  onClick={() => window.location.reload()}
                >
                  Retry
                </Button>
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "var(--default-cta)",
                    border: "none",
                  }}
                  onClick={() => window.location.replace(`/la/${la.name}/home`)}
                >
                  Go to Home
                </Button>
              </Space>
            }
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
