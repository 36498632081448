import { useSelector } from "react-redux";
import { StateTypes } from "../../model/data";
import { useMemo } from "react";
import { Redirect, Route } from "react-router-dom";

const DsoRoute = ({ path, component: Component, ...rest }: any) => {
  const loggedIn = useMemo(() => {
    const userToken = window.sessionStorage.getItem("currentUser");
    return userToken;
    //eslint-disable-next-line
  }, [window.sessionStorage.getItem("currentUser")]);
  const isDsoUser = useSelector((state: StateTypes) => state.user.isDsoUser);

  return (
    <Route
      {...rest}
      render={(props: any) => {
        //
        //------Checking for feature access brfore navigating
        //
        if (!rest.feature) return <Redirect to="no-page" />;
        else
          return loggedIn && isDsoUser ? (
            <Component {...props} />
          ) : (
            <Redirect to="home" />
          );
      }}
    />
  );
};

export default DsoRoute;
