import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { devtools } from "zustand/middleware";

type Branding = {
  branding: {
    primary: string;
    secondary: string;
    sidebar: string;
    cta: string;
  };
  iconography: {
    "pre-login": {
      primary?: {
        icon?: string;
        text?: string;
      };
      secondary?: {
        icon?: string;
        text?: string;
      };
    };
    "post-login": {
      primary?: {
        icon?: string;
        text?: string;
      };
      secondary?: {
        icon?: string;
        text?: string;
      };
    };
  };
};

export type ThemeTypes = {
  theme: Branding;
  setTheme: (data: Branding) => void;
};

const initialState: Branding = {
  branding: {
    primary: "",
    secondary: "",
    sidebar: "",
    cta: "",
  },
  iconography: {
    "pre-login": {
      primary: {
        icon: "",
        text: "Local Area Energy Planning",
      },
      secondary: {
        icon: "",
        text: "",
      },
    },
    "post-login": {
      primary: {
        icon: "",
        text: "",
      },
      secondary: {
        icon: "",
        text: "",
      },
    },
  },
};

const store = (set: any) => ({
  theme: initialState,
  setTheme: (data: Branding) => {
    set(() => ({
      theme: data,
    }));
  },
});

const useStore = create<ThemeTypes>()(
  devtools((set) => store(set), { name: "Theme" })
);

export const useThemeStore = <U>(selector: (state: any) => U) => {
  return useStore(selector, shallow);
};
