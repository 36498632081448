import { createSlice } from "@reduxjs/toolkit";
import { AccessTypes } from "./data";

const initialState: AccessTypes = {
  currentTab: "users",
  selectedUser: null,
  manageDatasetAccessVisible: false,
  revokeDatasetAccessVisible: false,
  inviteUser: { emails: [] },
  permissionGroups: [
    {
      id: 0,
      name: "New Group",
      description: "Create new permission group",
    },
  ],
  userList: {
    users: [],
    from_org: [],
    external: [],
    pending: [],
    failed: [],
  },
  loadingUserList: false,
  editExpirydateModalOpen: false,
};

const accessModel = createSlice({
  name: "access",
  initialState,
  reducers: {
    updateUserList(state, { payload }) {
      state.userList = { ...state.userList, ...payload };
    },
    updateLoadingUserList(state, { payload }) {
      state.loadingUserList = payload;
    },
    updateCurrentAccessTab(state, { payload }) {
      state.currentTab = payload;
    },
    updateAccessSeletedUser(state, { payload }) {
      state.selectedUser = payload;
    },
    updateManageDatasetAccessVisible(state, { payload }) {
      state.manageDatasetAccessVisible = payload;
    },
    updateRevokeDatasetAccessVisible(state, { payload }) {
      state.revokeDatasetAccessVisible = payload;
    },
    addEmail(state, { payload }) {
      const emails = state.inviteUser.emails;
      const email = payload.trim();
      if (!emails.includes(email)) emails.push(email);
    },
    removeEmail(state, { payload }) {
      const email = payload.trim();
      state.inviteUser.emails = state.inviteUser.emails.filter(
        (item) => item !== email
      );
    },
    clearEmails(state) {
      state.inviteUser.emails = [];
    },
    setPermissionGroups(state, { payload }) {
      const filteredId = state.permissionGroups.filter((c: any) => c.id === 0);
      const newPayload = [...payload];
      newPayload.push(filteredId[0]);
      state.permissionGroups = newPayload;
    },
    setEditExpirydateModalOpen(state, {payload}) {
      state.editExpirydateModalOpen = payload;
    }
  },
});

export const {
  updateUserList,
  updateLoadingUserList,
  updateCurrentAccessTab,
  updateAccessSeletedUser,
  updateManageDatasetAccessVisible,
  updateRevokeDatasetAccessVisible,
  addEmail,
  removeEmail,
  clearEmails,
  setPermissionGroups,
  setEditExpirydateModalOpen,
} = accessModel.actions;
export default accessModel.reducer;
