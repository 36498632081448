import { createSlice } from "@reduxjs/toolkit";
import { UserModelTypes } from "./data";

const initialState: UserModelTypes = {
  currentUser: {},
  loggedIn: false,
  data: {},
  uploadData: {},
  newUser: false,
  isAdmin: false,
  isDsoUser: false,
  permissions: {
    meta: {},
    read: [],
    write: [],
  },
  allUsers: [],
  continueWorking: {
    consulation_exists: false,
    project_exists: false,
    scenario_exists: false,
  },
  feature_flags: {},
};

const userModel = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUser(state, { payload }) {
      state.currentUser = payload;
      state.loggedIn = payload ? true : false;
    },

    saveData(state, { payload }) {
      state.data = payload;
    },

    updateData(state, { payload: { datasetId, data } }) {
      state.data[datasetId] = data;
    },

    clearUser: () => {
      window.localStorage.clear();
      return initialState;
    },

    setIsAdmin(state, { payload }) {
      state.isAdmin = payload;
    },
    setIsDsoUser(state, { payload }) {
      state.isDsoUser = payload;
    },
    setIsNewUser(state, { payload }) {
      state.newUser = payload;
    },
    updatePermissions(state, { payload }) {
      state.permissions = payload;
    },
    updateUploadData(state, { payload: { datasetId, data } }) {
      state.uploadData[datasetId] = data;
    },
    updateUploadDataProgress(state, { payload: { datasetId, progress } }) {
      state.uploadData[datasetId].loaded = progress;
    },
    updateToken(state, { payload }) {
      state.currentUser.token = payload;
    },
    updateAccessToken(state, { payload }) {
      state.currentUser.accessToken = payload;
    },
    updateMasterPlanRole(state, { payload }) {
      state.currentUser.masterPlanRole = payload;
    },
    updateAllUsers(state, { payload }) {
      state.allUsers = payload;
    },
    setContinueWorking(state, { payload }) {
      state.continueWorking = payload;
    },
    setFeatureFlags(state, { payload }) {
      state.feature_flags = payload;
    },
    updateAllUploadData(state, { payload }) {
      state.uploadData = payload;
    },
  },
});

export const {
  saveUser,
  clearUser,
  saveData,
  updateData,
  setIsAdmin,
  setIsDsoUser,
  updatePermissions,
  updateUploadData,
  updateUploadDataProgress,
  updateToken,
  updateAccessToken,
  updateAllUsers,
  setIsNewUser,
  setContinueWorking,
  setFeatureFlags,
  updateAllUploadData,
  updateMasterPlanRole,
} = userModel.actions;
export default userModel.reducer;
