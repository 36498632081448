import { createSlice } from "@reduxjs/toolkit";
import { DashboardTypes } from "./data";

const initialState: DashboardTypes = {
  datasetLoading: false,
  drawerVisible: true,
  activeTabKey: "1",
  uploaderVisible: false,
  dataTableActiveKey: "",
  dataTableVisible: false,
  carbonSavingsData: [],
  carbondataVisible: true,
  dfesRunSenario: true,
  isScenarioRunning: false,
  isTermAndConditionVisible: false,
  termsAccepted: true,
  isDsoCommentListOpen: false,
  dfesDataTableVisible: false,
  isModalVisible: false,
  dsoComment: {
    viewFeedBackModalOpen: false,
    entityType: "",
    selectedId: "",
    feedbackModalOpen: false,
    selectedLA: "",
  },
  assetText: "0",
  size: {
    width: 360,
    height: window.innerHeight,
  },
  activeBlog: [""],
};

const dashboardModel = createSlice({
  name: "deckgl",
  initialState,
  reducers: {
    setDatasetLoading(state, { payload }) {},
    setDrawerVisible(state, { payload }) {
      state.drawerVisible = payload;
    },
    setUploaderVisible(state, { payload }) {
      state.uploaderVisible = payload;
    },
    setActiveTabKey(state, { payload }) {
      state.activeTabKey = payload;
    },
    setDataTableActiveKey(state, { payload }) {
      state.dataTableActiveKey = payload;
    },
    setIsTermAndConditionVisible(state, { payload }) {
      state.isTermAndConditionVisible = payload;
    },
    setTermsAccepted(state, { payload }) {
      state.termsAccepted = payload;
    },
    setIsDsoCommentListOpen(state, { payload }) {
      state.isDsoCommentListOpen = payload;
    },
    setDataTableVisible(state, { payload }) {
      state.dataTableVisible = payload;
    },
    setSize(state, { payload }) {
      state.size = payload;
    },
    setCarbonSavingsData(state, { payload }) {
      state.carbonSavingsData = payload;
    },
    setcarbondataVisible(state, { payload }) {
      state.carbondataVisible = payload;
    },
    setdfesRunSenario(state, { payload }) {
      state.dfesRunSenario = payload;
    },
    setAssetText(state, { payload }) {
      state.assetText = payload;
    },
    setIsScenarioRunning(state, { payload }) {
      state.isScenarioRunning = payload;
    },
    setDsoComment(state, { payload }) {
      state.dsoComment = { ...state.dsoComment, ...payload };
    },
    setdfesDataTableVisible(state, { payload }) {
      state.dfesDataTableVisible = payload;
    },
    setIsModalVisible(state, { payload }) {
      state.isModalVisible = payload;
    },
    setActiveBlog(state, { payload }) {
      state.activeBlog = payload;
    },
  },
});

export const {
  setDatasetLoading,
  setDrawerVisible,
  setUploaderVisible,
  setActiveTabKey,
  setDataTableActiveKey,
  setDataTableVisible,
  setSize,
  setCarbonSavingsData,
  setcarbondataVisible,
  setdfesRunSenario,
  setIsTermAndConditionVisible,
  setTermsAccepted,
  setIsDsoCommentListOpen,
  setAssetText,
  setIsScenarioRunning,
  setdfesDataTableVisible,
  setIsModalVisible,
  setActiveBlog,
  setDsoComment,
} = dashboardModel.actions;

export default dashboardModel.reducer;
