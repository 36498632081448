import { createSlice } from "@reduxjs/toolkit";
import { ScenariosTypes } from "./data";

const initialState: ScenariosTypes = {
  openMap: false,
  consultationId: "",
  openPBHMap: false,
  tileUrl: "",
  baselineYear: 2023,
  scenarioOutputModal: false,
  scenarioResultsModal: false,
};

const scenariosModel = createSlice({
  name: "scenarios",
  initialState,
  reducers: {
    setOpenMap(state, action) {
      state.openMap = action.payload;
    },
    setConsultationId(state, action) {
      state.consultationId = action.payload;
    },
    setOpenPBHMap(state, action) {
      state.openPBHMap = action.payload;
    },
    setTileUrl(state, action) {
      state.tileUrl = action.payload;
    },
    setBaselineYear(state, action) {
      state.baselineYear = action.payload;
    },
    setScenarioOutputModal(state, action) {
      state.scenarioOutputModal = action.payload;
    },
    setScenarioResultsModal(state, {payload}) {
      state.scenarioResultsModal = payload;
    }
  },
});

export const {
  setOpenMap,
  setConsultationId,
  setOpenPBHMap,
  setTileUrl,
  setBaselineYear,
  setScenarioOutputModal,
  setScenarioResultsModal,
} = scenariosModel.actions;


export default scenariosModel.reducer;
