import {
  AccessIcon,
  DASHBOARDICON_SSEN_LAEP,
  DSODASHBOARDICON_SSEN_LAEP,
  DashboardIcon,
  DsoDashboardIcon,
  GUIDANCEICON_SSEN_LAEP,
  GuidanceIcon,
  HOMEICON_SSEN_LAEP,
  HomeIcon,
  MANAGEACCESSICON_SSEN_LAEP,
  MAPICON_SSEN_LAEP,
  MapIcon,
  PROGRESSTRACKERICON_SSEN_LAEP,
  PlanningProgressIcon,
  PlansAndForecastsIcon,
  ProgressIcon,
  SCENARIOSICON_SSEN_LAEP,
  STAKEHOLDERICON_SSEN_LAEP,
  ScenariosIcon,
  PortfoliosIcon,
  StakeholderIcon,
  MASTERPLANICON_SSEN_LAEP,
} from "../components/common/menu-icons";
import {
  LAEP_SSEN_CONNECTIONS_ICON,
  LAEP_SSEN_DEPRIVATIONICON,
  LAEP_SSEN_ENERGYICON,
  LAEP_SSEN_EVICON,
  LAEP_SSEN_FLEXIBILITY_ICON,
  LAEP_SSEN_FUNDING_ICON,
  LAEP_SSEN_HEATPUMP_ICON,
  LAEP_SSEN_LOWCARBON_ICON,
  LAEP_SSEN_NEWICON,
  LAEP_SSEN_PVICON,
  connectionIcon,
  deprivationIcon,
  energyEfficiencyIcon,
  evIcon,
  flexibilityIcon,
  fundingSourceIcon,
  heatpumpIcon,
  lowCarbonIcon,
  newIcon,
  pvIcon,
} from "../components/common/menu-icons/existingusericons";

export const UKPN_ICONS = {
  Home: HomeIcon,
  Map: MapIcon,
  Resources: GuidanceIcon,
  Dashboard: DashboardIcon,
  ProgressTracker: ProgressIcon,
  ManageAccess: AccessIcon,
  "Stakeholder-engagement": StakeholderIcon,
  Scenarios: ScenariosIcon,
  Portfolios: PortfoliosIcon,
  "Dso-dashboard": DsoDashboardIcon,
  PlansAndForecasts: PlansAndForecastsIcon,
  planningProgress: PlanningProgressIcon
};

export const LAEP_SSEN_ICONS = {
  Home: HOMEICON_SSEN_LAEP,
  Map: MAPICON_SSEN_LAEP,
  Resources: GUIDANCEICON_SSEN_LAEP,
  Dashboard: DASHBOARDICON_SSEN_LAEP,
  ProgressTracker: PROGRESSTRACKERICON_SSEN_LAEP,
  ManageAccess: MANAGEACCESSICON_SSEN_LAEP,
  "Stakeholder-engagement": STAKEHOLDERICON_SSEN_LAEP,
  Scenarios: SCENARIOSICON_SSEN_LAEP,
  Portfolios: PortfoliosIcon,
  "Dso-dashboard": DSODASHBOARDICON_SSEN_LAEP,
  PlansAndForecasts: PlansAndForecastsIcon,
  planningProgress: PlanningProgressIcon,
  masterPlan: MASTERPLANICON_SSEN_LAEP,
};

export const UKPN_EXISTINGUSER_ICONS = {
  new: newIcon,
  pv: pvIcon,
  deprivation: deprivationIcon,
  energy: energyEfficiencyIcon,
  ev: evIcon,
  connections: connectionIcon,
  hp: heatpumpIcon,
  flexibility: flexibilityIcon,
  funding: fundingSourceIcon,
  lowcarbon: lowCarbonIcon,
};

export const LAEP_SSEN_EXISTINGUSER_ICONS = {
  new: LAEP_SSEN_NEWICON,
  pv: LAEP_SSEN_PVICON,
  deprivation: LAEP_SSEN_DEPRIVATIONICON,
  energy: LAEP_SSEN_ENERGYICON,
  ev: LAEP_SSEN_EVICON,
  connections: LAEP_SSEN_CONNECTIONS_ICON,
  hp: LAEP_SSEN_HEATPUMP_ICON,
  flexibility: LAEP_SSEN_FLEXIBILITY_ICON,
  funding: LAEP_SSEN_FUNDING_ICON,
  lowcarbon: LAEP_SSEN_LOWCARBON_ICON,
};
