import { isEmpty } from "lodash";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";
import { StateTypes } from "../../model/data";

export default function PrivateRoute({
  path,
  component: Component,
  ...rest
}: any) {
  const isDnoUser = useSelector((s: StateTypes) => s.user.isDsoUser);
  const location = useLocation()

  const loggedIn = useMemo(() => {
    const userToken = window.sessionStorage.getItem("currentUser");
    return userToken;
    //eslint-disable-next-line
  }, [window.sessionStorage.getItem("currentUser")]);

  const getPreviousPageUrl = () => {
    return sessionStorage.getItem('previousPageUrl');
  };

  const setPreviousPageUrl = (url: any) => {
    sessionStorage.setItem('previousPageUrl', url);
  };

  useEffect(() => {
    const sourceUrl = getPreviousPageUrl();
    const isLaFound = location?.pathname?.search("la/undefined")
    setTimeout(() => {
      if (isEmpty(sourceUrl) && isLaFound === -1) {
        setPreviousPageUrl(location?.pathname + location?.search);
      }
    }, 500)
  }, [location]);

  const isLaSelected = useMemo(() => {
    const la = window.sessionStorage.getItem("local_authority");
    return !isEmpty(la);
    //eslint-disable-next-line
  }, [window.sessionStorage.getItem("local_authority")]);

  return (
    <Route
      {...rest}
      render={(props: any) => {
        //
        //------Checking for feature access brfore navigating
        //
        if (!rest.feature) return <Redirect to="no-page" />;
        else
          return loggedIn && isDnoUser && !isLaSelected ? (
            <Redirect to={"/auth/select-la"} />
          ) : loggedIn && isLaSelected ? (
            <Component {...props} />
          ) : <Redirect to="/auth/login" />
            ;
      }}
    />
  );
}
